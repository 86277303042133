export const meta: BlockMeta = {
  editMode: "both",
  title: "Home intro",
  keywords: ["intro"],
  icon: "visibility",
  cache: false,
  tags: ["root"],
}

import { Button } from "@components/atoms/Button"
import { ProductDemo } from "@components/atoms/ProductDemo"
import { defineBlock, EditableText, useInlineEditableValue } from "eddev/blocks"
import { motion } from "framer-motion"
import { Fragment, useMemo } from "react"
import { ResponsiveImageFragment } from "../../types.graphql"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"

export default defineBlock("home/home-intro", (props) => {
  const [title, setTitle] = useInlineEditableValue("title")
  const [content, setContent] = useInlineEditableValue("content")

  const titleAnimation: { word: string; delay: number }[] = useMemo(() => {
    if (typeof title === "string") {
      const words = title
        .split(/(<br>|\s+)/)
        .filter(Boolean)
        .filter((string) => string !== " ")

      let adjustedIndex = 0
      return words.map((word) => {
        if (word === "<br>") {
          return { word, delay: -1 }
        }
        return { word, delay: adjustedIndex++ }
      })
    }

    return []
  }, [title])

  return (
    <div className="w-full overflow-hidden">
      <div className="grid-auto items-center pt-[80px] md:pt-[160px] lg:max-w-[1280px] xl:max-w-[1440px]">
        <div className="col-span-10 col-start-2 md:col-span-8 md:col-start-3">
          {env.admin ? (
            <EditableText
              className="type-title-xxl text-text text-center w-full"
              store="title"
              placeholder="Enter title..."
            />
          ) : (
            <h1 className="type-title-xxl text-text text-center w-full">
              {titleAnimation.map((word, i) => {
                if (word.word === "<br>")
                  return (
                    <Fragment key={i}>
                      <br />
                    </Fragment>
                  )
                return (
                  <motion.span
                    key={i}
                    className="inline-block px-3"
                    dangerouslySetInnerHTML={{ __html: word.word }}
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: word.delay * 0.15, duration: 0.4, ease: [0.6, 0, 0, 1] }}
                  />
                )
              })}
            </h1>
          )}
        </div>

        <div className="col-span-12 h-0" />

        <div className="col-span-10 col-start-2 md:col-span-4 md:col-start-5">
          {env.admin ? (
            <EditableText
              className="type-body-l text-text text-center w-full py-9"
              store="content"
              placeholder="Enter content..."
            />
          ) : (
            <motion.p
              className="inline-block type-body-l text-text text-center w-full py-8"
              dangerouslySetInnerHTML={{ __html: content ?? "" }}
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: (titleAnimation.length + 1) * 0.15, duration: 0.8, ease: [0, 0, 0, 1] }}
            />
          )}
        </div>

        {(props.button1 || props.button2) && (
          <div className="col-span-12 flex gap-4 justify-center items-center">
            {props.button1 && (
              <motion.div
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: (titleAnimation.length + 3) * 0.15, duration: 1.2, ease: [0.6, 0, 0, 1] }}
              >
                <Button
                  style="primary"
                  size="xl"
                  href={props.button1.url ?? undefined}
                  target={props.button1.target ? "_blank" : undefined}
                >
                  {props.button1.title}
                </Button>
              </motion.div>
            )}
            {props.button2 && (
              <motion.div
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: (titleAnimation.length + 3) * 0.15, duration: 1.2, ease: [0.6, 0, 0, 1] }}
              >
                <Button
                  style="secondary"
                  size="xl"
                  rounded
                  href={props.button2.url ?? undefined}
                  target={props.button2.target ? "_blank" : undefined}
                >
                  {props.button2.title}
                </Button>
              </motion.div>
            )}
          </div>
        )}
        <div className="col-span-12 h-0 pt-8 md:pt-[56px]" />

        {props?.slides && (
          <>
            <div className="col-span-12 pb-8 md:pb-10 px-8">
              <ZoomBlock slides={props.slides} delay={titleAnimation.length + 1} mobileImage={props.mobileImage} />
            </div>
          </>
        )}
      </div>
    </div>
  )
})

const ZoomBlock = ({
  slides,
  delay,
  mobileImage,
}: {
  slides: ({
    title: string | null
    iconChoices: string | null
    slide: ResponsiveImageFragment | null
  } | null)[]
  delay: number
  mobileImage: ResponsiveImageFragment | null
}) => {
  return (
    <motion.div
      initial={{ transform: "translateY(50%) scale(1.1)" }}
      animate={{ transform: "translateY(0%) scale(1)" }}
      style={{ transformOrigin: "top center" }}
      transition={{ duration: 1.8, ease: [0.6, 0, 0, 1] }}
    >
      <div className="hidden md:block">
        <ProductDemo slides={slides} />
      </div>
      <div className="md:hidden p-2 overflow-hidden rounded-md shadow-glow bg-bg">
        <ResponsiveImage {...mobileImage} />
      </div>
    </motion.div>
  )
}
